import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

const VehicleForm = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage();
  const firestore = getFirestore();

  const [vehicleName, setVehicleName] = useState("");
  const [perDayPrice, setPerDayPrice] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [doors, setDoors] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [loading, setLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedImage(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user === null) {
        // const uid = user.uid;
        navigate("/admin-login");
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (uploadedImage) {
      const StorageRef = storageRef(storage, `images/${uploadedImage.name}`);
      const uploadTask = uploadBytesResumable(StorageRef, uploadedImage);

      // Set loading to true when upload starts
      setLoading(true);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.error(error.message);
          setLoading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(StorageRef);
          try {
            await addDoc(collection(firestore, "vehicles"), {
              timestamp: new Date(),
              vehicleName: vehicleName,
              imageUrl: downloadURL,
              perDayPrice: perDayPrice,
              category: category,
              title: title,
              doors: doors,
            });

            setProgress(0);
            setVehicleName("");
            setPerDayPrice("");
            setCategory("");
            setTitle("");
            setDoors("");
            setUploadedImage(null);
            // Show success alert
            setAlertMessage("Vehicle added successfully");
            setAlertSeverity("success");
            setShowAlert(true);
          } catch (error) {
            console.error("Error adding Vehicle Details: ", error);
            // Show error alert
            setAlertMessage("Error adding Vehicle Details");
            setAlertSeverity("error");
            setShowAlert(true);
          } finally {
            // Hide loading indicator when upload is completed
            setLoading(false);
          }
        }
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "#F5F5F5",
          px: 2,
        }}
      >
        <Container maxWidth='sm'>
          <Card
            sx={{
              p: 4,
              borderRadius: "20px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              bgcolor: "white",
            }}
          >
            <Stack
              spacing={2}
              sx={{
                position: "fixed",
                top: 16,
                right: 16,
                zIndex: 9999,
              }}
            >
              {showAlert && (
                <Alert
                  severity={alertSeverity}
                  onClose={() => setShowAlert(false)}
                  sx={{ fontSize: "0.9rem", p: 1 }}
                >
                  {alertMessage}
                </Alert>
              )}
            </Stack>

            <Typography
              variant='h4'
              align='center'
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#3f51b5",
                mb: 4,
              }}
            >
              Vehicle Information Form
            </Typography>

            <Box component='form' onSubmit={handleSubmit} noValidate>
              <TextField
                label='Vehicle Name'
                fullWidth
                margin='normal'
                value={vehicleName}
                onChange={(e) => setVehicleName(e.target.value)}
                sx={{
                  "& .MuiInputLabel-root": { fontSize: "1rem" },
                  "& .MuiInputBase-input": { fontSize: "1rem" },
                }}
              />

              <TextField
                label='Per Day Price'
                fullWidth
                margin='normal'
                type='number'
                value={perDayPrice}
                onChange={(e) => setPerDayPrice(e.target.value)}
                sx={{
                  "& .MuiInputLabel-root": { fontSize: "1rem" },
                  "& .MuiInputBase-input": { fontSize: "1rem" },
                }}
              />

              <FormControl fullWidth margin='normal'>
                <InputLabel sx={{ fontSize: "1rem" }}>Category</InputLabel>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  sx={{ fontSize: "1rem" }}
                >
                  <MenuItem value='ECONOMY'>Economy</MenuItem>
                  <MenuItem value='COMFORT'>Comfort</MenuItem>
                  <MenuItem value='PREMIUM'>Premium</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label='Title (Vehicle Inner Details)'
                fullWidth
                margin='normal'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{
                  "& .MuiInputLabel-root": { fontSize: "1rem" },
                  "& .MuiInputBase-input": { fontSize: "1rem" },
                }}
              />

              <FormControl fullWidth margin='normal'>
                <InputLabel sx={{ fontSize: "1rem" }}>Doors</InputLabel>
                <Select
                  value={doors}
                  onChange={(e) => setDoors(e.target.value)}
                  sx={{ fontSize: "1rem" }}
                >
                  <MenuItem value='2'>2</MenuItem>
                  <MenuItem value='4'>4</MenuItem>
                  <MenuItem value='5'>5</MenuItem>
                  <MenuItem value='6'>6</MenuItem>
                </Select>
              </FormControl>

              <div
                {...getRootProps()}
                style={{
                  margin: "16px 0",
                  padding: "16px",
                  border: "1px dashed #ccc",
                  textAlign: "center",
                  backgroundColor: "#fafafa",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
              >
                <input {...getInputProps()} />
                <p style={{ fontSize: "0.9rem" }}>
                  Drag 'n' drop an image here, or click to select an image
                </p>
                {uploadedImage && (
                  <img
                    src={URL.createObjectURL(uploadedImage)}
                    alt='Uploaded'
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      marginTop: "10px",
                    }}
                  />
                )}
              </div>

              <Button
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
                sx={{
                  mt: 3,
                  py: 1.5,
                  fontSize: "1rem",
                  borderRadius: "8px",
                  boxShadow: "none",
                  textTransform: "none",
                }}
              >
                Submit
              </Button>
            </Box>

            {loading && (
              <Box
                sx={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 9999,
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default VehicleForm;
