import { UploadFile } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";

const BlogForm = () => {
  const [title, setTitle] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage();
  const firestore = getFirestore();
  const [blogs, setBlogs] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user === null) {
        navigate("/admin-login");
      }
    });

    fetchBlogs();

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Fetch blogs from Firestore
  const fetchBlogs = async () => {
    const blogCollection = collection(firestore, "blogs");
    const blogSnapshot = await getDocs(blogCollection);
    const blogList = blogSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setBlogs(blogList);
  };

  // Upload image and return its URL
  const uploadImage = async (file) => {
    const imageRef = storageRef(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(imageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          (error) => {
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(imageRef);
              resolve(downloadURL);
            } catch (error) {
              reject(error);
            }
          }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      // Step 1: Check and upload image if available
      let imageUrl = "";
      if (image) {
        imageUrl = await uploadImage(image); // Upload image and get URL
      }

      // Step 2: Prepare the new blog data with image URL if available
      const newBlog = {
        title,
        createDate: new Date(createDate),
        content,
        imageUrl,
        createdAt: new Date(),
      };

      // Step 3: Add the new blog entry to Firestore
      await addDoc(collection(firestore, "blogs"), newBlog);

      // Reset form fields
      setTitle("");
      setCreateDate("");
      setContent("");
      setImage(null);
      setProgress(0);
      setAlertMessage("Blog added successfully");
      setAlertSeverity("success");
      setShowAlert(true);

      // Fetch blogs after adding a new blog to update the UI
      fetchBlogs();
    } catch (error) {
      console.error("Error adding blog: ", error);
      setAlertMessage("Error adding blog");
      setAlertSeverity("error");
      setShowAlert(true);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
      <Container maxWidth='md'>
        <Box component='form' onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Typography variant='h4' component='h1' gutterBottom>
            Create New Blog Post
          </Typography>
          {showAlert && (
              <Stack sx={{ mt: 2 }} spacing={2}>
                <Alert severity={alertSeverity}>{alertMessage}</Alert>
              </Stack>
          )}

          <TextField
              label='Title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              required
              margin='normal'
          />
          <TextField
              label='Create Date'
              type='date'
              value={createDate}
              onChange={(e) => setCreateDate(e.target.value)}
              fullWidth
              required
              margin='normal'
              InputLabelProps={{
                shrink: true,
              }}
          />
          <Box
              sx={{
                p: 4,
                mb: 2,
                mt: 2,
                borderRadius: "8px",
                border: "2px dashed #ccc",
                bgcolor: "#f9f9f9",
                textAlign: "center",
                position: "relative",
              }}
          >
            <input
                accept='image/*'
                type='file'
                id='file-upload'
                style={{ display: "none" }}
                onChange={handleFileSelect}
            />
            <label htmlFor='file-upload'>
              <Button
                  variant='contained'
                  component='span'
                  startIcon={<UploadFile />}
                  sx={{ mb: 2 }}
              >
                Select File
              </Button>
            </label>
            <Typography variant='body1' sx={{ mb: 2 }}>
              {image ? image.name : "No file selected"}
            </Typography>
            {progress > 0 && (
                <Box sx={{ mt: 2 }}>
                  <CircularProgress variant="determinate" value={progress} />
                  <Typography variant="caption" sx={{ mt: 1 }}>{`${progress}%`}</Typography>
                </Box>
            )}
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
            >
              <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
                {alertMessage}
              </Alert>
            </Snackbar>
          </Box>
          <ReactQuill
              value={content}
              onChange={setContent}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "link",
                "image",
              ]}
              style={{ height: "40vh", marginBottom: "20px" }}
          />
          <Button
              variant='contained'
              color='primary'
              type='submit'
              fullWidth
              sx={{
                mt: 5,
                py: 1.5,
                fontSize: "1rem",
                borderRadius: "8px",
                boxShadow: "none",
                textTransform: "none",
              }}
              disabled={loading}
          >
            Add Blog
          </Button>
          {loading && (
              <Box display='flex' justifyContent='center' mt={2}>
                <CircularProgress />
              </Box>
          )}
        </Box>
      </Container>
  );
};

export default BlogForm;
