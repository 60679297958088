import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import OurService from "../components/ourService";
import Banner from "../components/Banner";
import Banner2 from "../components/Banner-2";
import Navbar from "../components/Navbar";


import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import Footer from "../components/Footer";
import LoadingProgress from "../components/loadingProgress";
import locationDialogBG from "../images/banners/australia-image.jpg";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { FaArrowCircleRight } from "react-icons/fa";


function YourComponent() {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const handleWhatsAppCall = () => {
    const phoneNumber = +610400920512;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    // Check if the page has loaded successfully
    const handleLoad = () => {
      setLoading(false);  
    };

    window.addEventListener("load", handleLoad);

     
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the time as needed

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
      window.removeEventListener("load", handleLoad);  
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    const ipGeoLocationApiKey = process.env.REACT_APP_IPGEOLOCATION_API_KEY;
    const ipGeoLocationApiUrl = `https://api.ipgeolocation.io/ipgeo?apiKey=${ipGeoLocationApiKey}`;

    fetch(ipGeoLocationApiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch  IP geolocation data");
        }
        return response.json();
      })
      .then((country) => {
      
        if (
          country.country_code2 !== "AU" ||
          country.country_code3 !== "AUS" ||
          country.country_name !== "Australia"
         
        ) {
          setOpenLocationDialog(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching IP geolocation data:", error);
      });
  }, []);

  const handleClose = () => {
    setOpenLocationDialog(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, 9000);

    return () => clearTimeout(timer);
  }, []);


  return (
    <>


      <Dialog
        open={openLocationDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.89), rgba(255, 255, 255, 1)), url(${locationDialogBG})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            width: "80%",
            maxWidth: "700px",
            height: "80%",
            maxHeight: "400px",
            borderRadius: "16px",
            '@media (max-width: 600px)': {
              maxHeight: "40%",
            } 
          },
        }}
      >
        <DialogTitle>
          <Typography
            variant='h2'
            color='primary'
            sx={{ textAlign: "center", color: "#2d5b89", fontWeight: 400 ,  '@media (max-width: 600px)': {
              fontSize: '40px',  
            }}}
          >
            Oops!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant='h6'
            sx={{ textAlign: "center", color: "#465362",'@media (max-width: 600px)': {
              fontSize: '14px',  
            } }}
          >
            <Typography
              variant='h4'
              color='primary'
              sx={{ textAlign: "center", mb: 2, color: "#011936",'@media (max-width: 600px)': {
                fontSize: '18px',  
              }  }}
            >
              It seems you're not in Australia.
            </Typography>
            Our services are currently available only to users in Australia. We
            hope to expand to your region soon.
            <Typography
              variant='h6'
              color='primary'
              sx={{ textAlign: "center", color: "#465362",mt:2 }}
            >
              Thank you for visiting!
            </Typography>
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" ,mb:2}}>
          <Button
            onClick={handleClose}
            color='primary'
            variant='contained'
            sx={{ background: "#80b918" }}
            size='large'
            endIcon={<FaArrowCircleRight />}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingProgress />
        </div>
      ) : (
        <>
          <Navbar />
          <Hero />
          <Banner />
          <BookCar />
          <PlanTrip />
          <Banner2 />
          <OurService />
          <Testimonials />
          <Faq />
          <Footer />
          <div className='wh-api-bg'>
            <div onClick={handleWhatsAppCall}>
              <div className='wh-api'>
                <div className='wh-fixed whatsapp-pulse'>
                  <button className='wh-ap-btn'></button>
                </div>
              </div>
            </div>
          </div>
          <div className='scroll-up-button'>
            {isVisible && (
              <button onClick={scrollToTop}>
                <FaArrowUp />
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default YourComponent;
