import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/main.css";
import { Container, Button } from "@mui/material";
import { CircularProgress } from "@mui/material";

const BlogDetail = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const [blog, setBlog] = useState(null);
  const firestore = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogDoc = doc(firestore, "blogs", id);
        const blogSnapshot = await getDoc(blogDoc);
        if (blogSnapshot.exists()) {
          setBlog(blogSnapshot.data());
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching blog details: ", error);
      }
    };

    fetchBlog();
  }, [firestore, id]);

  if (!blog) return <div style={{ textAlign: "center", padding: "20px" }}>
  <CircularProgress />  
</div>;
  const handleBackButtonClick = () => {
    navigate("/blog");
  };

  return (
    <>
      <Navbar />

      <div className='container '>
        <Container maxWidth='xl' sx={{ marginTop: 15, marginBottom: 10 }}>
          <Button
            variant='outlined'
            sx={{ mb: 3 }}
            onClick={handleBackButtonClick}
          >
            Back to blogs
          </Button>

          <div className='blog-details-main '>
            <div className='blog-detail'>
              <h1>{blog.title}</h1>
              <div className='blog-create-date'>
                {blog.createDate
                  ? new Date(blog.createDate.seconds * 1000).toDateString()
                  : "No Date"}
              </div>
              {blog.imageUrl && (
                <img
                  className='blog-detail-img'
                  src={blog.imageUrl}
                  alt={blog.title}
                />
              )}
             
             <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            />
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetail;
