import React from "react";
import "../styles/main.css";
import AboutUsImg from "../images/about-us/5aboutsusimg.png";

const AboutUsCompo = () => {
  return (
    <>
      <div className='container'>
        <div className='row  about-us'>
          <div className='col-md-12'>
            <div className='plan-container__title'>
              <h2> About Us</h2>
            </div>

            <div className='about-us-container'>
              <div className='about-us-image'>
                <img src={AboutUsImg} alt='' />
              </div>

              <div className='about-us-paragraph'>
                <h1>
                Welcome to<span className='red-text'> EZ CAR RENTALS</span>
                </h1>

                <h3>
                  Pronounced <span className='red-text'> Eeezee (easy)</span>{" "}
                  Car Rentals, your trusted car rental service in Chadstone,
                  VIC.
                </h3>
                <p>
                  Founded in 2019 with humble beginnings with just 1 van, we
                  have grown to become a family owned and operated car rental
                  serving the Melbourne community with all car, van, suv or ute
                  rental needs. We pride in what we do, so we do what we do best
                  and provide best quality cars at industry beating prices. With
                  a diverse fleet of vehicles and a commitment to customer
                  satisfaction, we strive to make every journey smooth and
                  enjoyable. Having had over 10 years industry experience, we
                  understand the importance of customer service and engagement.
                  Our goal is to expand our service to other parts of locally
                  and state-wide so you can benefit from cheap prices and
                  excellent cars with unmatched customer service wherever you
                  are! Contact us now to book your next rental!.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsCompo;


