import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";

import { CircularProgress } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { deleteObject, getStorage, ref as storageRef } from "firebase/storage";
import { useNavigate } from "react-router-dom";

const AdminTable = ({ data, onEdit }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage();
  const firestore = getFirestore();

  const [vehicleDetailsData, setVehicleDetailsData] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user === null) {
        navigate("/admin-login");
      }
    });
    fetchVehicleDetails();
  }, []);

  const fetchVehicleDetails = async () => {
    setLoading(true); // Show loading spinner
    try {
      const vehiclesCollection = collection(firestore, "vehicles");
      const vehiclesSnapshot = await getDocs(vehiclesCollection);
      const vehiclesData = vehiclesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVehicleDetailsData(vehiclesData);
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const onDelete = async (id, imageUrl) => {
    try {
      setLoading(true);
      await deleteDoc(doc(firestore, "vehicles", id));

      const imageRef = storageRef(storage, imageUrl);
      await deleteObject(imageRef);

      fetchVehicleDetails();

      // Show success alert
      setAlertMessage("Vehicle deleted successfully");
      setAlertSeverity("success");
      setShowAlert(true);
    } catch (error) {
      console.error("Error deleting Vehicle: ", error);
      // Show error alert
      setAlertMessage("Error deleting Vehicle");
      setAlertSeverity("error");
      setShowAlert(true);
    } finally {
      // Hide loading spinner when deletion process is completed
      setLoading(false);
    }
  };

  const [editingItem, setEditingItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [loading, setLoading] = useState(false);

  const [editedData, setEditedData] = useState({
    vehicleName: "",
    perDayPrice: "",
    category: "",
    title: "",
    doors: "",
    imageUrl: "",
  });

  const handleEdit = (item) => {
    setEditingItem(item);
    setEditedData(item); // Pre-fill the form fields with existing data
    setOpenModal(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      // Update the document with the edited data
      await updateDoc(doc(firestore, "vehicles", editingItem.id), editedData);
      setOpenModal(false);
      // Optionally, you may want to fetch the updated data again to reflect changes in the UI
      fetchVehicleDetails();

      // Show success alert
      setAlertMessage("vehicle details updated successfully");
      setAlertSeverity("success");
      setShowAlert(true);
    } catch (error) {
      console.error("Error updating document: ", error);
      // Show error alert
      setAlertMessage("Error updating document");
      setAlertSeverity("error");
      setShowAlert(true);
    } finally {
      // Hide loading spinner when deletion process is completed
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Stack
        spacing={2}
        sx={{
          position: "fixed",
          top: 16,
          right: 16,
          zIndex: 9999,
        }}
      >
        {showAlert && (
          <Alert severity={alertSeverity} onClose={() => setShowAlert(false)}>
            {alertMessage}
          </Alert>
        )}
      </Stack>

      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <TableContainer
        component={Paper}
        sx={{ borderRadius: "8px", overflow: "hidden" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Vehicle Name</TableCell>
              <TableCell>Per Day Price</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Doors</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicleDetailsData.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.vehicleName}</TableCell>
                <TableCell>{item.perDayPrice}</TableCell>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.doors}</TableCell>
                <TableCell>
                  <img
                    src={item.imageUrl}
                    alt='EZ Car Rentals Vehicle'
                    style={{
                      width: "50px",
                      height: "auto",
                      borderRadius: "4px",
                    }}
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleEdit(item)}
                    aria-label='edit'
                    sx={{ mr: 1 }}
                  >
                    <AiFillEdit />
                  </IconButton>
                  <IconButton
                    onClick={() => onDelete(item.id, item.imageUrl)}
                    aria-label='delete'
                    sx={{ color: "red" }}
                  >
                    <MdDeleteForever />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            width: "90%",
            maxWidth: "600px",
          }}
        >
          <Typography variant='h6' component='h2' gutterBottom>
            Edit Vehicle Information
          </Typography>
          <Box component='form' noValidate>
            <TextField
              label='Vehicle Name'
              name='vehicleName'
              value={editedData.vehicleName}
              onChange={handleInputChange}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Per Day Price'
              name='perDayPrice'
              value={editedData.perDayPrice}
              onChange={handleInputChange}
              fullWidth
              margin='normal'
              type='number'
            />
            <FormControl fullWidth margin='normal'>
              <InputLabel sx={{ fontSize: "1rem" }}>Category</InputLabel>
              <Select
                name='category'
                value={editedData.category}
                onChange={handleInputChange}
                sx={{ fontSize: "1rem" }}
              >
                <MenuItem value='ECONOMY'>Economy</MenuItem>
                <MenuItem value='COMFORT'>Comfort</MenuItem>
                <MenuItem value='PREMIUM'>Premium</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label='Title'
              name='title'
              value={editedData.title}
              onChange={handleInputChange}
              fullWidth
              margin='normal'
            />
            <FormControl fullWidth margin='normal'>
              <InputLabel sx={{ fontSize: "1rem" }}>Doors</InputLabel>
              <Select
                name='doors'
                value={editedData.doors}
                onChange={handleInputChange}
                sx={{ fontSize: "1rem" }}
              >
                <MenuItem value='2'>2</MenuItem>
                <MenuItem value='4'>4</MenuItem>
                <MenuItem value='5'>5</MenuItem>
                <MenuItem value='6'>6</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              fullWidth
              sx={{
                mt: 3,
                py: 1.5,
                fontSize: "1rem",
                borderRadius: "8px",
                boxShadow: "none",
                textTransform: "none",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default AdminTable;
