import React from 'react';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar />

            <div style={{ maxWidth: '800px', margin: 'auto', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' , marginTop:'60px'}}>
                <h1 style={{ color: '#0056b3' }}>Privacy Policy for EZ MOTOR GROUP PTY LTD TRADING AS EZ Car Rentals</h1>
                <p><strong>Effective Date:</strong> 01/11/2024</p>

                <p>At EZ Car Rentals, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website.</p>

                <h2 style={{ color: '#0056b3' }}>1. When do we collect your information?</h2>
                <p>We collect personal information from you when you fill out our enquiry form or subscribe to our newsletter. The information we may collect online includes:</p>
                <ul>
                    <li>Name</li>
                    <li>Phone Number</li>
                    <li>Email Address</li>
                </ul>
                <p>Additionally, we may also collect any other information you provide while interacting with us.</p>

                <h2 style={{ color: '#0056b3' }}>2. Use of Information</h2>
                <p>We use the information you provide to:</p>
                <ul>
                    <li>Respond to your enquiries</li>
                    <li>Send you updates and information regarding your subscription</li>
                    <li>Provide and market our products and services</li>
                    <li>Improve our services and website functionality</li>
                </ul>

                <h2 style={{ color: '#0056b3' }}>3. Cookies</h2>
                <p>Our website may use cookies to enhance user experience. Cookies are small files stored on your device that help us analyze web traffic and improve our website. You can choose to accept or decline cookies through your browser settings. However, this may prevent you from taking full advantage of our website.</p>
                <p>We use Google Analytics to gather statistics about how this website is accessed. Google Analytics uses cookies to gather information for statistical reporting. The information generated by the cookie about your use of the website will be transmitted to and stored by Google on servers located outside of Australia. No personally identifying information is recorded or provided to Google.</p>

                <h2 style={{ color: '#0056b3' }}>4. Subscription List</h2>
                <p>If you choose to subscribe to our mailing list, we will send you promotional materials and updates about our services. You can unsubscribe at any time by following the instructions included in our emails.</p>

                <h2 style={{ color: '#0056b3' }}>5. Data Security</h2>
                <p>We take reasonable precautions to protect your personal information from unauthorized access or disclosure. However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure.</p>

                <h2 style={{ color: '#0056b3' }}>6. Sharing Your Information</h2>
                <p>We do not sell, trade, or otherwise transfer your personal information to outside parties, except when required by law or as necessary to fulfill your request.</p>
                <p>We may disclose your personal information to:</p>
                <ul>
                    <li>Our related companies</li>
                    <li>Insurance companies</li>
                    <li>State government entities responsible for motor vehicle registrations and driving infringements</li>
                    <li>Other companies or individuals who assist us in providing services or who perform functions on our behalf (such as mailing houses or our live chat service provider) who are generally contract-bound to protect your privacy</li>
                    <li>Law enforcement bodies such as the police, who seek access to your personal information for law enforcement purposes</li>
                    <li>Anyone else to whom you have authorized us to disclose it</li>
                    <li>Your emergency contacts</li>
                </ul>

                <h2 style={{ color: '#0056b3' }}>7. Your Rights</h2>
                <p>You have the right to request access to the personal information we hold about you and to request corrections if necessary. If you wish to exercise these rights, please contact us using the information provided below.</p>

                <h2 style={{ color: '#0056b3' }}>8. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website with an updated effective date.</p>

                <h2 style={{ color: '#0056b3' }}>9. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>

                <div style={{ marginTop: '20px', fontStyle: 'italic' }}>
                    <p><strong>EZ MOTOR GROUP PTY LTD</strong><br />
                        33B CLIFFORD STREET, HUNTINGDALE VIC 3136<br />
                        <a href="mailto:EZCARRENTALSMELBOURNE@GMAIL.COM">EZCARRENTALSMELBOURNE@GMAIL.COM</a><br />
                        0400920512</p>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default PrivacyPolicy;
