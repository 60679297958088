import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function Banner() {
  const handleCallNowClick = () => {
    document
      .querySelector(".contact-page")
      .scrollIntoView({ behavior: "smooth" });
  };
  return (
      <>
          <section className="banner-section-2">
              <div className="banner-section-card-2">
                  <div className="info-card">
                      <ul className="info-list">
                          <li> <FontAwesomeIcon
                              icon={faCheckCircle}/>&nbsp; We accept <strong>International Driver's License</strong></li>
                          <li> <FontAwesomeIcon
                              icon={faCheckCircle}/>&nbsp; No lock-in contracts  </li>
                          <li> <FontAwesomeIcon
                              icon={faCheckCircle}/>&nbsp; Drivers <strong>21 and over</strong> accepted
                          </li>
                          <li> <FontAwesomeIcon
                              icon={faCheckCircle}/>&nbsp; Fair pricing — <strong>No surge pricing</strong>
                          </li>
                      </ul>
                  </div>
              </div>
          </section>


      </>
  );
}

export default Banner;
