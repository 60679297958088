import { Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Container,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

function ReviewsPage() {
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({
    name: "",
    review: "",
    rating: "",
    avatar: "",
  });
  const [editingReview, setEditingReview] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const auth = getAuth();
  const firestore = getFirestore();

  // Fetch reviews from Firestore
  const fetchReviews = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(firestore, "google_Reviews")
      );
      const reviewsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReviews(reviewsList);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const handleAddReview = async () => {
    try {
      await addDoc(collection(firestore, "google_Reviews"), newReview);
      setAlertMessage("Review added successfully!");
      setAlertSeverity("success");
      setAlertOpen(true);
      setNewReview({ name: "", review: "", rating: "", avatar: "" });
      fetchReviews(); // Refresh the list
    } catch (error) {
      console.error("Error adding review:", error);
      setAlertMessage("Failed to add review.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleEditReview = async (id, updatedReview) => {
    try {
      const reviewDoc = doc(firestore, "google_Reviews", id);
      await updateDoc(reviewDoc, updatedReview);
      setAlertMessage("Review updated successfully!");
      setAlertSeverity("success");
      setAlertOpen(true);
      setEditingReview(null);
      setNewReview({ name: "", review: "", rating: "", avatar: "" }); // Clear form fields
      fetchReviews(); // Refresh the list
    } catch (error) {
      console.error("Error updating review:", error);
      setAlertMessage("Failed to update review.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleDeleteReview = async (id) => {
    try {
      await deleteDoc(doc(firestore, "google_Reviews", id));
      setAlertMessage("Review deleted successfully!");
      setAlertSeverity("success");
      setAlertOpen(true);
      fetchReviews(); // Refresh the list
    } catch (error) {
      console.error("Error deleting review:", error);
      setAlertMessage("Failed to delete review.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // Handle form field changes
  const handleFieldChange = (e) => {
    setNewReview({ ...newReview, [e.target.name]: e.target.value });
  };

  // Set form fields when editing
  useEffect(() => {
    if (editingReview) {
      setNewReview({
        name: editingReview.name,
        review: editingReview.review,
        rating: editingReview.rating,
        avatar: editingReview.avatar,
      });
    }
  }, [editingReview]);

  return (
    <Container maxWidth='md'>
      <Box padding={2}>
        <Typography variant='h4' gutterBottom>
          {editingReview ? "Edit Review" : "Add Review"}
        </Typography>
        <Box mb={2}>
          <TextField
            label='Name'
            name='name'
            value={newReview.name}
            onChange={handleFieldChange}
            fullWidth
            margin='normal'
          />
          <TextField
            label='Review'
            name='review'
            value={newReview.review}
            onChange={handleFieldChange}
            fullWidth
            margin='normal'
          />
          <TextField
            label='Rating'
            name='rating'
            value={newReview.rating}
            onChange={handleFieldChange}
            fullWidth
            margin='normal'
          />
          <TextField
            label='Avatar URL'
            name='avatar'
            value={newReview.avatar}
            onChange={handleFieldChange}
            fullWidth
            margin='normal'
          />
          <Button
            variant='contained'
            color='primary'
            onClick={() =>
              editingReview
                ? handleEditReview(editingReview.id, newReview)
                : handleAddReview()
            }
          >
            {editingReview ? "Update Review" : "Add Review"}
          </Button>
        </Box>

        <Typography variant='h5' gutterBottom>
          Reviews
        </Typography>
        <TableContainer component={Box} mb={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Review</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Avatar</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviews.map((review) => (
                <TableRow key={review.id}>
                  <TableCell>{review.name}</TableCell>
                  <TableCell>{review.review}</TableCell>
                  <TableCell>{review.rating}</TableCell>
                  <TableCell>
                    <img
                      src={review.avatar}
                      alt={review.name}
                      style={{ width: "50px", borderRadius: "50%" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => setEditingReview(review)}>
                      <Edit />
                    </Button>
                    <Button onClick={() => handleDeleteReview(review.id)}>
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity={alertSeverity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
}

export default ReviewsPage;
