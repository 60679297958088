import React, { useState, useEffect } from "react";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
  Stack,
} from "@mui/material";

const BlogCompo = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const firestore = getFirestore();
  const MAX_LENGTH = 200;
  const placeholderImage = "https://fakeimg.pl/600x400?text=No+Image&font=bebas";

  const truncateContent = (content, maxLength) => {
    return content.length <= maxLength ? content : content.slice(0, maxLength) + '...';
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const blogCollection = collection(firestore, "blogs");
        const blogQuery = query(blogCollection, orderBy("createDate", "asc")); // Ensure "asc" order is set
        const blogSnapshot = await getDocs(blogQuery);

        const blogList = blogSnapshot.docs.map((doc) => {
          const blogData = doc.data();
          return {
            id: doc.id,
            ...blogData,
          };
        });

        setBlogs(blogList);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [firestore]);

  const handleCardClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
      <Container maxWidth="xl" sx={{ marginTop: 15, marginBottom: 10 }}>
        <h1 className="blog-title">Blogs</h1>
        {loading ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <CircularProgress />
            </div>
        ) : (
            <Stack>
              <div className="blog-main">
                {blogs.map((blog) => (
                    <Card className="blog-card" key={blog.id} onClick={() => handleCardClick(blog.id)}>
                      <CardMedia
                          component="img"
                          alt={blog.title}
                          className="blog-card-image"
                          image={blog.imageUrl || placeholderImage}
                      />
                      <CardContent sx={{ p: 0 }} className="blog-card-content">
                        <Typography variant="h5" component="div" className="blog-card-title">
                          {blog.title}
                        </Typography>
                        <Typography variant="body2" className="blog-card-body">
                          <div
                              dangerouslySetInnerHTML={{
                                __html: truncateContent(blog.content, MAX_LENGTH),
                              }}
                          />
                        </Typography>
                        <Typography className="small-left-aligned-text">
                          {blog.createDate
                              ? new Date(blog.createDate.seconds * 1000).toDateString()
                              : "No Date"}
                        </Typography>
                      </CardContent>
                    </Card>
                ))}
              </div>
            </Stack>
        )}
      </Container>
  );
};

export default BlogCompo;
