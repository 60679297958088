
import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutUsCompo from "../components/AboutUsCompo";
import "../styles/main.css";
 

const AboutUs = () => {
  return (

  <>

 <Navbar />
 <AboutUsCompo />
 <Footer />
 

 </>
  );
};

export default AboutUs;
