import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardLayoutBasic from '../module/admin/pages/DashboardLayoutBasic'; 
import NotFound from "../Pages/NotFoundPage";
import "../dist/styles.css";
import Home from "../Pages/Home";
import AdminTable from "../module/admin/pages/ViewVehicleDetails";
import UploadDetails from "../module/admin/pages/UploadVehicleDetails";
import Dashboard from "../module/admin/pages/DashboardLayoutBasic";
import BlogForm from "../module/admin/pages/UploadBlogDetails";
import ViewBlogDetails from "../module/admin/pages/ViewBlogDetails";
import SubscriptionsPage from '../module/admin/pages/ViewSubscribeList';
import ViewGoogleReviews from '../module/admin/pages/ViewGoogleReviews'


function AdminRoutes() {
  return (
    <DashboardLayoutBasic>
      <Routes>
        <Route path="upload-vehicle-details" element={<UploadDetails />} />
        <Route path="view-vehicle-details" element={<AdminTable />} />
        <Route path="upload-blog-details" element={<BlogForm />} />
        <Route path="view-blog-details" element={<ViewBlogDetails />} />
        <Route path="subscriptions" element={<SubscriptionsPage />} />
        <Route path="view-google-reviews" element={<ViewGoogleReviews />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </DashboardLayoutBasic>
  );
}

export default AdminRoutes;
