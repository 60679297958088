import { Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore, orderBy, query,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

const BlogListPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [loading, setLoading] = useState(false);
  const firestore = getFirestore();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const blogCollection = collection(firestore, "blogs");
      // Create a query with ordering by "createDate"
      const orderedQuery = query(blogCollection, orderBy("createDate", "desc"));
      const blogSnapshot = await getDocs(orderedQuery);
      const blogList = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogList);
    } catch (error) {
      console.error("Error fetching blogs: ", error);
      setAlertMessage("Error fetching blogs");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleEditClick = (blog) => {
    setSelectedBlog(blog);
    setDialogOpen(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      await deleteDoc(doc(firestore, "blogs", id));
      setAlertMessage("Blog deleted successfully");
      setAlertSeverity("success");
      setAlertOpen(true);
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog: ", error);
      setAlertMessage("Error deleting blog");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleUpdateBlog = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const blogRef = doc(firestore, "blogs", selectedBlog.id);
      await updateDoc(blogRef, {
        title: selectedBlog.title,
        createDate: selectedBlog.createDate,
        content: selectedBlog.content,
      });
      setAlertMessage("Blog updated successfully");
      setAlertSeverity("success");
      setAlertOpen(true);
      fetchBlogs();
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating blog: ", error);
      setAlertMessage("Error updating blog");
      setAlertSeverity("error");
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedBlog(null);
  };

  return (
    <Container maxWidth='md'>
      <Typography variant='h4' component='h1' gutterBottom>
        Blog List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogs.map((blog) => (
              <TableRow key={blog.id}>
                <TableCell>{blog.title}</TableCell>
                <TableCell>
                  {new Date(
                    blog.createDate.seconds * 1000
                  ).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <IconButton
                    color='primary'
                    onClick={() => handleEditClick(blog)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color='secondary'
                    onClick={() => handleDeleteClick(blog.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar Alert */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* Edit Modal */}
      {selectedBlog && (
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          maxWidth='md'
          fullWidth
        >
          <DialogTitle>Edit Blog Post</DialogTitle>
          <DialogContent>
            <Box component='form' onSubmit={handleUpdateBlog} sx={{ mt: 3 }}>
              <TextField
                label='Title'
                value={selectedBlog.title}
                onChange={(e) =>
                  setSelectedBlog({ ...selectedBlog, title: e.target.value })
                }
                fullWidth
                required
                margin='normal'
              />
              <TextField
                label='Create Date'
                type='date'
                value={
                  new Date(selectedBlog.createDate.seconds * 1000)
                    .toISOString()
                    .split("T")[0]
                }
                onChange={(e) =>
                  setSelectedBlog({
                    ...selectedBlog,
                    createDate: e.target.value,
                  })
                }
                fullWidth
                required
                margin='normal'
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <ReactQuill
                value={selectedBlog.content}
                onChange={(content) =>
                  setSelectedBlog({ ...selectedBlog, content })
                }
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "font",
                  "size",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "link",
                  "image",
                ]}
                style={{ height: "40vh", marginBottom: "20px" }}
              />
              <Button
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
                sx={{
                  mt: 5,
                  py: 1.5,
                  fontSize: "1rem",
                  borderRadius: "8px",
                  boxShadow: "none",
                  textTransform: "none",
                }}
              >
                {loading ? <CircularProgress size={24} /> : "Update Blog"}
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='primary'>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default BlogListPage;
