import AddIcon from "@mui/icons-material/Add";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import StarIcon from "@mui/icons-material/Star";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../images/logo/Ezcarrentals-logo.png";

const NAVIGATION = [
  {
    kind: "header",
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={Logo}
          alt='EZ Car Rentals Logo'
          style={{
            width: "auto",
            height: "100%",
            maxWidth: "100%",
            objectFit: "contain",
          }}
        />
      </div>
    ),
  },
  {
    segment: "",
    title: "Vehicle",
    icon: <CarRepairIcon />,
    children: [
      {
        segment: "admin-dashboard/upload-vehicle-details",
        title: "Add New Vehicle ",
        icon: <AddIcon />,
      },
      {
        segment: "admin-dashboard/view-vehicle-details",
        title: "View Vehicle ",
        icon: <VisibilityIcon />,
      },
    ],
  },
  {
    segment: "#",
    title: "Blog",
    icon: <NewspaperIcon />,
    children: [
      {
        segment: "admin-dashboard/upload-blog-details",
        title: "Add New Blog ",
        icon: <AddIcon />,
      },
      {
        segment: "admin-dashboard/view-blog-details",
        title: "View Blog ",
        icon: <VisibilityIcon />,
      },
    ],
  },
  {
    segment: "admin-dashboard/view-google-reviews",
    title: "Google Reviews",
    icon: <StarIcon />,
  },
  {
    segment: "admin-dashboard/subscriptions",
    title: "Subscribe List ",
    icon: <SubscriptionsIcon />,
  },

  {
    kind: "header",
    title: "Other",
  },

  {
    segment: "",
    title: "Back To Home ",
    icon: <HomeIcon />,
  },
];

const demoTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function DashboardLayoutBasic({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [openMenu, setOpenMenu] = useState({}); // State to handle dropdowns

  const handleToggle = (index) => {
    setOpenMenu((prevOpen) => ({ ...prevOpen, [index]: !prevOpen[index] }));
  };

  // Check if current path matches for active highlighting
  const isActive = (path) => location.pathname.includes(path);

  return (
    <ThemeProvider theme={demoTheme}>
      <Box sx={{ display: "flex" }}>
        <Drawer
          sx={{
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
            },
          }}
          variant='permanent'
          anchor='left'
        >
          <List>
            {NAVIGATION.map((item, index) => (
              <React.Fragment key={index}>
                {item.kind === "header" ? (
                  <Typography variant='h6' sx={{ padding: "16px 8px" }}>
                    {item.title}
                  </Typography>
                ) : item.kind === "divider" ? (
                  <Divider />
                ) : item.children ? (
                  // Render parent items with dropdown
                  <>
                    <ListItem button onClick={() => handleToggle(index)}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.title} />
                      <ExpandMore
                        sx={{
                          transform: openMenu[index]
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </ListItem>
                    <Collapse in={openMenu[index]} timeout='auto' unmountOnExit>
                      <List component='div' disablePadding>
                        {item.children.map((child, childIndex) => (
                          <ListItem
                            key={childIndex}
                            button
                            sx={{ pl: 4 }}
                            selected={isActive(child.segment)}
                            onClick={() => navigate(`/${child.segment}`)}
                          >
                            <ListItemIcon>{child.icon}</ListItemIcon>
                            <ListItemText primary={child.title} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <ListItem
                    button
                    selected={isActive(item.segment)}
                    onClick={() => navigate(`/${item.segment}`)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItem>
                )}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

DashboardLayoutBasic.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayoutBasic;
