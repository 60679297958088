
import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BlogCompo from "../components/Blog";
import "../styles/main.css";
 

const Blog = () => {
  return (

  <>

 <Navbar />
 <BlogCompo />
 <Footer />
 

 </>
  );
};

export default Blog;
